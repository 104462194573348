import React, { CSSProperties, useMemo } from 'react'
import { Routes, Route, Outlet, useLocation, Location } from 'react-router-dom'
import { Modal } from '@mui/base'
import { ViewWrapper } from './components/ViewWrapper'
import { DialogProvider } from './dialogs'
import {
  StateProvider,
  createStore,
  asyncFlow,
} from '@the-platform-company/appbuilder-react-state'
import { RobotenDnDProvider } from '@the-platform-company/react-drag-drop'
import { useConnectionSecrets } from '@the-platform-company/appbuilder-react-connector'
const ModalsView = React.lazy(
  () => import(/* webpackChunkName: "views/modals" */ './views/Modals')
)
const MasterView = React.lazy(
  () => import(/* webpackChunkName: "views/master" */ './views/Master')
)
const DialogsNewView = React.lazy(
  () => import(/* webpackChunkName: "views/new" */ './views/DialogsNew')
)
const DialogsUserMenuView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/user-menu" */ './views/DialogsUserMenu')
)
const DialogsApplicationMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/application-menu" */ './views/DialogsApplicationMenu'
    )
)
const DialogsBatchProgressView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/batch-progress" */ './views/DialogsBatchProgress'
    )
)
const DialogsFileContextMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/file-context-menu" */ './views/DialogsFileContextMenu'
    )
)
const DialogsFileVersionContextMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/file-version-context-menu" */ './views/DialogsFileVersionContextMenu'
    )
)
const ModalsCreateFolderView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/create-folder" */ './views/ModalsCreateFolder'
    )
)
const ModalsRenameView = React.lazy(
  () => import(/* webpackChunkName: "views/rename" */ './views/ModalsRename')
)
const ModalsMoveToView = React.lazy(
  () => import(/* webpackChunkName: "views/move-to" */ './views/ModalsMoveTo')
)
const ModalsShareView = React.lazy(
  () => import(/* webpackChunkName: "views/share" */ './views/ModalsShare')
)
const ModalsApproveBatchesView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/approve-batches" */ './views/ModalsApproveBatches'
    )
)
const ModalsEmptyTrashView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/empty-trash" */ './views/ModalsEmptyTrash'
    )
)
const ModalsTrashedFolderRestoreView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/trashed-folder-restore" */ './views/ModalsTrashedFolderRestore'
    )
)
const ModalsCreateSharedDrivesView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/create-shared-drives" */ './views/ModalsCreateSharedDrives'
    )
)
const ModalsVersionsView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/versions" */ './views/ModalsVersions')
)
const ModalsImportSpecView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/import-spec" */ './views/ModalsImportSpec'
    )
)
const ModalsUntitledViewView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/untitled-view" */ './views/ModalsUntitledView'
    )
)
const MasterHomeView = React.lazy(
  () => import(/* webpackChunkName: "views/home" */ './views/MasterHome')
)
const MasterFolderListView = React.lazy(
  () => import(/* webpackChunkName: "views/list" */ './views/MasterFolderList')
)
const MasterSettingsUserSettingsView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/user-settings" */ './views/MasterSettingsUserSettings'
    )
)
const DriveAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/DriveAuthCallback" */ './components/connectors/DriveAuthCallback'
    )
)
const AccountAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/AccountAuthCallback" */ './components/connectors/AccountAuthCallback'
    )
)
const ContactsAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/ContactsAuthCallback" */ './components/connectors/ContactsAuthCallback'
    )
)
const MarketplaceAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/MarketplaceAuthCallback" */ './components/connectors/MarketplaceAuthCallback'
    )
)
const ContactsDriveAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/ContactsDriveAuthCallback" */ './components/connectors/ContactsDriveAuthCallback'
    )
)
const ConnectorsAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/ConnectorsAuthCallback" */ './components/connectors/ConnectorsAuthCallback'
    )
)
const store = createStore()

const modalStyles: CSSProperties = {
  position: 'fixed',
  zIndex: '1300',
  inset: '0px',
}
const App: React.FC = () => {
  const location = useLocation()

  const state = (location.state as { backgroundLocation?: Location }) ?? {}

  const connections = useConnectionSecrets({
    connectionNames: [
      'drive',
      'account',
      'contacts',
      'marketplace',
      'contactsDrive',
      'connectors',
    ],
  })
  const assets = useMemo(
    () => ({
      emptySvg: '/assets/empty-svg/empty.svg',
      appIcon_01Svg: '/assets/app-icon-01-svg/app_icon_01.svg',
      appIcon_09Svg: '/assets/app-icon-09-svg/app_icon_09.svg',
      appIcon_03Svg: '/assets/app-icon-03-svg/app_icon_03.svg',
      appIcon_02Svg: '/assets/app-icon-02-svg/app_icon_02.svg',
      appIcon_05Svg: '/assets/app-icon-05-svg/app_icon_05.svg',
      appIcon_04Svg: '/assets/app-icon-04-svg/app_icon_04.svg',
      appIcon_07Svg: '/assets/app-icon-07-svg/app_icon_07.svg',
      appIcon_06Svg: '/assets/app-icon-06-svg/app_icon_06.svg',
      appIcon_08Svg: '/assets/app-icon-08-svg/app_icon_08.svg',
      editSvg: '/assets/edit-svg/edit.svg',
      edit2Svg: '/assets/edit2-svg/edit2.svg',
      faviconPng: '/assets/favicon-png/favicon.png',
      trashFilesSvg: '/assets/trash-files-svg/TrashFiles.svg',
    }),
    []
  )
  return (
    <>
      <StateProvider
        additionalState={{
          assets,
          connections,
        }}
        store={store}
      >
        <RobotenDnDProvider>
          <DialogProvider>
            <>
              <React.Suspense fallback={null}>
                <Routes location={state?.backgroundLocation ?? location}>
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'f2d3eb7b-7ddb-43ba-bd7d-59795a3f738f'}
                      >
                        <MasterView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/"
                      element={
                        <ViewWrapper
                          namespace={'2e571303-d876-4560-a772-ff3f21f5fd0c'}
                        >
                          <MasterHomeView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/folder/:parentId"
                      element={
                        <ViewWrapper
                          namespace={'28e4ce58-5c47-4b2b-ae1a-86b2db6c207e'}
                        >
                          <MasterFolderListView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/settings/users/:userId"
                      element={
                        <ViewWrapper
                          namespace={'7acbb87e-9f15-4cf8-8bf1-df7e1062f36d'}
                        >
                          <MasterSettingsUserSettingsView />
                        </ViewWrapper>
                      }
                    />
                  </Route>
                  <Route
                    path="/dialogs/new/:parentId"
                    element={
                      <ViewWrapper
                        namespace={'d3b68f18-eb89-4f06-92d1-6cc953f6aee1'}
                      >
                        <DialogsNewView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/dialogs/user-menu"
                    element={
                      <ViewWrapper
                        namespace={'45b4e7f6-1c2f-416d-8a13-7a9755a47fda'}
                      >
                        <DialogsUserMenuView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/dialogs/application-menu"
                    element={
                      <ViewWrapper
                        namespace={'96f5e74d-65f4-49a3-8fc2-ae7347b41d9b'}
                      >
                        <DialogsApplicationMenuView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/dialogs/batch-progress"
                    element={
                      <ViewWrapper
                        namespace={'3a65a5d5-672b-4162-9d8d-b829302a94ee'}
                      >
                        <DialogsBatchProgressView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/dialogs/file-context-menu/:fileType/:fileId/parent/:parentId"
                    element={
                      <ViewWrapper
                        namespace={'86bc2c8f-502f-4a84-a738-23abd8045da0'}
                      >
                        <DialogsFileContextMenuView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/dialogs/file-version-context-menu/:fileId/version/:versionId"
                    element={
                      <ViewWrapper
                        namespace={'8a13f3db-4d0e-4a8f-86ea-aa4a875d9eb2'}
                      >
                        <DialogsFileVersionContextMenuView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'bf63ef09-d801-49ac-bc10-63ec22bdca64'}
                      >
                        <ModalsView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/create-folder/:parentId"
                      element={
                        <ViewWrapper
                          namespace={'f8079f54-0747-4980-abfd-9e28fe18b574'}
                        >
                          <ModalsCreateFolderView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/rename/:fileId"
                      element={
                        <ViewWrapper
                          namespace={'885e074f-eebd-4d81-8831-489051886a48'}
                        >
                          <ModalsRenameView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/move-to/:fileId"
                      element={
                        <ViewWrapper
                          namespace={'5c520feb-f99f-49c6-b240-a64a36065e08'}
                        >
                          <ModalsMoveToView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/share/:fileId"
                      element={
                        <ViewWrapper
                          namespace={'72712971-4082-40ef-a541-542198a9bbdb'}
                        >
                          <ModalsShareView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/approve-batches"
                      element={
                        <ViewWrapper
                          namespace={'6b3f9a82-c9e3-4d84-8796-cd5856b5c583'}
                        >
                          <ModalsApproveBatchesView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/empty-trash/:userId"
                      element={
                        <ViewWrapper
                          namespace={'88474974-a3fd-40b5-aee9-9bd8679a2cc6'}
                        >
                          <ModalsEmptyTrashView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/restore/:fileId"
                      element={
                        <ViewWrapper
                          namespace={'bb221ad7-25ca-43c3-bb34-00e5a0c72bfb'}
                        >
                          <ModalsTrashedFolderRestoreView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/create-shared-drives/:parentId"
                      element={
                        <ViewWrapper
                          namespace={'b7d0c76e-865e-4edd-b8c9-afec15f306ae'}
                        >
                          <ModalsCreateSharedDrivesView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/versions/:fileId"
                      element={
                        <ViewWrapper
                          namespace={'20545f65-012d-4dc1-8e88-5b5f21e41adf'}
                        >
                          <ModalsVersionsView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/import-spec/:fileId"
                      element={
                        <ViewWrapper
                          namespace={'b144e7bd-35c1-4f7f-877f-2068ebdbf7bd'}
                        >
                          <ModalsImportSpecView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/import1"
                      element={
                        <ViewWrapper
                          namespace={'46b85af1-103c-4f0f-99ca-118c5d53c808'}
                        >
                          <ModalsUntitledViewView />
                        </ViewWrapper>
                      }
                    />
                  </Route>

                  <Route
                    path="/auth/drive/callback"
                    element={<DriveAuthCallback />}
                  />

                  <Route
                    path="/auth/account/callback"
                    element={<AccountAuthCallback />}
                  />

                  <Route
                    path="/auth/contacts/callback"
                    element={<ContactsAuthCallback />}
                  />

                  <Route
                    path="/auth/marketplace/callback"
                    element={<MarketplaceAuthCallback />}
                  />

                  <Route
                    path="/auth/contacts-drive/callback"
                    element={<ContactsDriveAuthCallback />}
                  />

                  <Route
                    path="/auth/connectors/callback"
                    element={<ConnectorsAuthCallback />}
                  />
                </Routes>

                {/* Show the modal when a `backgroundLocation` is set */}
                {state?.backgroundLocation && (
                  <Routes>
                    <Route
                      path=""
                      element={
                        <Modal
                          open={!!state?.backgroundLocation}
                          style={modalStyles}
                        >
                          <div>
                            <Outlet />
                          </div>
                        </Modal>
                      }
                    >
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'f2d3eb7b-7ddb-43ba-bd7d-59795a3f738f'}
                          >
                            <MasterView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/"
                          element={
                            <ViewWrapper
                              namespace={'2e571303-d876-4560-a772-ff3f21f5fd0c'}
                            >
                              <MasterHomeView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/folder/:parentId"
                          element={
                            <ViewWrapper
                              namespace={'28e4ce58-5c47-4b2b-ae1a-86b2db6c207e'}
                            >
                              <MasterFolderListView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/settings/users/:userId"
                          element={
                            <ViewWrapper
                              namespace={'7acbb87e-9f15-4cf8-8bf1-df7e1062f36d'}
                            >
                              <MasterSettingsUserSettingsView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                      <Route
                        path="/dialogs/new/:parentId"
                        element={
                          <ViewWrapper
                            namespace={'d3b68f18-eb89-4f06-92d1-6cc953f6aee1'}
                          >
                            <DialogsNewView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/dialogs/user-menu"
                        element={
                          <ViewWrapper
                            namespace={'45b4e7f6-1c2f-416d-8a13-7a9755a47fda'}
                          >
                            <DialogsUserMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/dialogs/application-menu"
                        element={
                          <ViewWrapper
                            namespace={'96f5e74d-65f4-49a3-8fc2-ae7347b41d9b'}
                          >
                            <DialogsApplicationMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/dialogs/batch-progress"
                        element={
                          <ViewWrapper
                            namespace={'3a65a5d5-672b-4162-9d8d-b829302a94ee'}
                          >
                            <DialogsBatchProgressView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/dialogs/file-context-menu/:fileType/:fileId/parent/:parentId"
                        element={
                          <ViewWrapper
                            namespace={'86bc2c8f-502f-4a84-a738-23abd8045da0'}
                          >
                            <DialogsFileContextMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/dialogs/file-version-context-menu/:fileId/version/:versionId"
                        element={
                          <ViewWrapper
                            namespace={'8a13f3db-4d0e-4a8f-86ea-aa4a875d9eb2'}
                          >
                            <DialogsFileVersionContextMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'bf63ef09-d801-49ac-bc10-63ec22bdca64'}
                          >
                            <ModalsView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/create-folder/:parentId"
                          element={
                            <ViewWrapper
                              namespace={'f8079f54-0747-4980-abfd-9e28fe18b574'}
                            >
                              <ModalsCreateFolderView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/rename/:fileId"
                          element={
                            <ViewWrapper
                              namespace={'885e074f-eebd-4d81-8831-489051886a48'}
                            >
                              <ModalsRenameView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/move-to/:fileId"
                          element={
                            <ViewWrapper
                              namespace={'5c520feb-f99f-49c6-b240-a64a36065e08'}
                            >
                              <ModalsMoveToView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/share/:fileId"
                          element={
                            <ViewWrapper
                              namespace={'72712971-4082-40ef-a541-542198a9bbdb'}
                            >
                              <ModalsShareView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/approve-batches"
                          element={
                            <ViewWrapper
                              namespace={'6b3f9a82-c9e3-4d84-8796-cd5856b5c583'}
                            >
                              <ModalsApproveBatchesView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/empty-trash/:userId"
                          element={
                            <ViewWrapper
                              namespace={'88474974-a3fd-40b5-aee9-9bd8679a2cc6'}
                            >
                              <ModalsEmptyTrashView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/restore/:fileId"
                          element={
                            <ViewWrapper
                              namespace={'bb221ad7-25ca-43c3-bb34-00e5a0c72bfb'}
                            >
                              <ModalsTrashedFolderRestoreView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/create-shared-drives/:parentId"
                          element={
                            <ViewWrapper
                              namespace={'b7d0c76e-865e-4edd-b8c9-afec15f306ae'}
                            >
                              <ModalsCreateSharedDrivesView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/versions/:fileId"
                          element={
                            <ViewWrapper
                              namespace={'20545f65-012d-4dc1-8e88-5b5f21e41adf'}
                            >
                              <ModalsVersionsView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/import-spec/:fileId"
                          element={
                            <ViewWrapper
                              namespace={'b144e7bd-35c1-4f7f-877f-2068ebdbf7bd'}
                            >
                              <ModalsImportSpecView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/import1"
                          element={
                            <ViewWrapper
                              namespace={'46b85af1-103c-4f0f-99ca-118c5d53c808'}
                            >
                              <ModalsUntitledViewView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                    </Route>
                  </Routes>
                )}
              </React.Suspense>
            </>
          </DialogProvider>
        </RobotenDnDProvider>
      </StateProvider>
    </>
  )
}
export default App
